import styled from 'styled-components';
import { themeGet } from 'styled-system';

const AboutUsSectionWrapper = styled.section`
  padding: 80px 0 50px;
  overflow: hidden;

  @media (max-width: 990px) {
    padding: 60px 0 40px 0;
  }
  @media (max-width: 680px) {
    .container div:first-child>h2{font-size:30px !important;}

    .row {
      display: flex;
      flex-direction: column-reverse;
    }
    .group-gallery .col1 {
      display: inline-block;
      width: 100% !important;
      text-align: right;
      margin: 0 !important;
    }
    .group-gallery .col1 img {
      width: 70%;
      float: right;
      margin: 25px 0 0;
    }
    .feature__block{margin-bottom: 8px !important;}
    .feature__block .content__wrapper h2{font-size: 15px;}
    a.buttonstyle__ButtonStyle-kympzq.jHRotG.reusecore__button{
      padding: 8px 10px !important;min-height: 20px !important;font-size: 14px !important;
      margin-right: 0 !important;margin-top: 20px !important;
    }
  }

  .col {
    align-self: center;
  }

  .group-gallery {
    box-shadow: none;
    display: flex;
    flex-wrap: wrap;
    .col1 {
      width: calc(60% - 30px);
      margin-left: 300px;
      .gatsby-image-wrapper {
        margin-bottom: 30px;
      }
    }
    .col2 {
      width: calc(40% - 30px);
      align-self: center;
      margin-right: 30px;
    }
    img {
      max-width: 100%;
      height: auto;
      margin-bottom: 30px;
      object-fit: contain;
    }
  }

  .feature__block {
    align-items: center;
    margin-bottom: 14px;
    .icon__wrapper {
      color: ${themeGet('colors.primary', '#10ac84')};
      margin-right: 10px;
    }
    .content__wrapper {
      h2 {
        margin-bottom: 0;
      }
    }
  }

  .reusecore__button {
    background-color:#63E6D9;
    margin-top: 36px;
    transition: all 0.3s ease;
    &:hover {
      box-shadow: 0px 9px 20px -5px rgba(16, 172, 132, 0.57);
    }
  }
  div:first-child>h2 {
    font-size: 40px !important;
    text-align: center;
    color: #1f1f1f;
    text-transform: uppercase;
  }
  .icon__wrapper i {
    color: #63E6D9;
  }
  a.buttonstyle__ButtonStyle-kympzq.jHRotG.reusecore__button {
    margin-top: 25px !important;
    display: inline-block;
  }
  a.buttonstyle__ButtonStyle-kympzq.jHRotG.reusecore__button {
    background-color: #63E6D9;
    padding: 14px 20px;
    color: #fff;
    min-height: 48px;
    font-weight: 700;
    margin-right: 10px;
  }
`;

export default AboutUsSectionWrapper;
