import styled from 'styled-components';
import { themeGet } from 'styled-system';

const QualitySectionWrapper = styled.section`
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
  .contact {
    float: left;
    width: 50%;
    min-height: 100%
  }
  .kuNHYN {
    max-width: 1170px;
    width: 100%;
    padding: 0 30px;
  }
  .contact-content {
    float: right;
    width: 50%;
    height: 100%;
    text-align: center;
    overflow: hidden;
  }
  div {
    overflow: hidden;
    display: flex;
    flex-wrap: wrap;
    flex-flow: wrap;
  }
  .col-contact {
    width: 40%;
  }
  .col-contact h2 {
    text-align: left;
    font-size: 40px;
    color: #1f1f1f;
    font-family: "Poppins", sans-serif;
    font-weight: 600;
    letter-spacing: -0.025em;
    box-sizing: border-box;
    margin-top: 0;
    margin-bottom: 0;
  }
  .col-contact p.contacto.bajada {
    color: #707070;
    font: 400 16px Roboto;
  }
  .col-contact p.resaltado {
    background: #E1D8A8;
    font: italic 17px Roboto;
    padding: 15px;
  }
  .col-contact2 {
    width: 50% !important;
    margin-left: 10%;
  }
  .col-contact2 a {
    width: 50%;
    display: block;
    color: #000000;
    font: 16px/40px Roboto;
    padding: 40px 0;
    font-weight: 600;
    float: left;
  }
  .col-contact2 a i {
    background: #51A29A;
    color: #fff;
    border-radius: 5px;
    width: 40px;
    height: 40px;
    display: inline-block;
    text-align: center;
    line-height: 40px;
    font-size: 22px;
    margin-right: 10px;
    float: left;
  }
  .col-contact2 a i::before{content:"";}
  .col-contact2 a i.icon-mail-alt {
    background: #51A29A url(./assets/image/agency/icon-correo.svg) !important;
    background-size: 21px auto !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
  }
  .col-contact2 a i.icon-phone {
    background: #51A29A url(./assets/image/agency/icon-telefono.svg) !important;
    background-size: 20px auto !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
  }
  .col-contact2 a i.icon-location {
    background: #51A29A url(./assets/image/agency/icon-ubicacion.svg) !important;
    background-size: 17px auto !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
  }
  .col-contact2 a i.icon-whatsapp {
    background: #51A29A url(./assets/image/agency/icon-whatsapp.svg) !important;
    background-size: 21px auto !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
  } 
  .icondireccion span {
    display: table;
    line-height: 20px;
    max-width: calc(100% - 50px);
    height: 40px;
  }
  .icondireccion span strong {
    display: block;
    font-size: 14px;
    font-weight: 400;
  }
  @media (max-width: 680px) {
    height:auto !important;
    .col-contact, .col-contact2{width: 100% !important;margin-left: 0 !important;}
    .col-contact h2{font-size: 30px;margin-top:30px;}
    .col-contact2 a{width: 100%;padding: 10px 0;}
  }
`;

const CounterUpArea = styled.div`
`;

export { CounterUpArea };
export default QualitySectionWrapper;
