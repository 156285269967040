import styled from 'styled-components';
import { themeGet } from 'styled-system';
import FooterIamge from 'common/src/assets/image/agency/footer-bg.png';

const FooterWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
  overflow: hidden;
  .icon-mobile-whatsapp{
    display: inline-block;
    width: 50px;
    height: 50px;
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 80%;
    line-height: 50px;
    background: green url(./assets/image/agency/icon-whatsapp.svg) !important;
    background-repeat: no-repeat !important;
    background-position: center center !important;
    text-indent: -50000px;
    text-align: center;
    display:none;
  }
  @media (max-width: 990px) {
    padding-bottom: 30px;
  }
  @media (max-width: 767px) {
    padding-bottom: 10px;
    div>div {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
    .icon-mobile-whatsapp{display:inline-block;}
  }
`;

const List = styled.ul``;

const ListItem = styled.li`
  a {
    color: ${themeGet('colors.textColor', 'rgba(52, 61, 72, 0.8)')};
    font-size: 14px;
    line-height: 36px;
    transition: all 0.2s ease;
    &:hover,
    &:focus {
      outline: 0;
      text-decoration: none;
      color: ${themeGet('colors.quoteText', '#343d48')};
    }
  }
`;

export { List, ListItem };

export default FooterWrapper;
