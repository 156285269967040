import styled from 'styled-components';

const NewsletterSectionWrapper = styled.section`
  padding: 0;
  @media (max-width: 990px) {
    padding: 40px 0 60px 0;
  }
  @media (max-width: 680px) {
    padding: 0 !important;margin-top: 25px;
  }

  div {
    padding: 0;
    max-width: 100% !important;
  }
  div img {
    width: 100% !important;
  }
`;
export default NewsletterSectionWrapper;
