import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import Image from 'common/src/components/Image';
//import FeatureImage from 'common/src/assets/image/agency/clientes-desktop.png';
import {
  Accordion,
  AccordionItem,
  AccordionTitle,
  AccordionBody,
  IconWrapper,
  OpenIcon,
  CloseIcon,
} from 'common/src/components/Accordion';
import { Icon } from 'react-icons-kit';
import { plus } from 'react-icons-kit/entypo/plus';
import { minus } from 'react-icons-kit/entypo/minus';
import FaqSectionWrapper from './faqSection.style';


const FaqSection = ({
  sectionHeader,
  sectionTitle,
  sectionSubTitle,
  titleStyle,
  descriptionStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        faq {
          id
          description
          expend
          title
        }
      }
    }
  `);

  return (
    <FaqSectionWrapper id="clientesSection">
      <Container>
        <Box {...sectionHeader}>
          <Heading content="CLIENTES" {...sectionTitle} />
        </Box>
        <Box className="row">
           <picture class="imageClient">
              <source srcset="./assets/image/agency/clientes-desktop.png" media="(min-width: 800px)"/>
              <source srcset="./assets/image/agency/clientes-mobile.png" media="(max-width: 799px)"/>
              <img src="./assets/image/agency/clientes-desktop.png" alt="example"/>
            </picture>
        </Box>
      </Container>
    </FaqSectionWrapper>
  );
};

// FaqSection style props
FaqSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
};

// FaqSection default style
FaqSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: ['40px', '56px'],
  },
  // sub section default style
  sectionSubTitle: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#10ac84',
    mb: '10px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['36px', '40px'],
    fontWeight: '600',
    color: '#1f1f1f',
    fontFamily: '"Poppins", sans-serif',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // accordion title default style
  titleStyle: {
    fontSize: ['16px', '19px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // accordion description default style
  descriptionStyle: {
    fontSize: '15px',
    color: '#343d48cc',
    lineHeight: '1.75',
    mb: '0',
  },
};

export default FaqSection;
