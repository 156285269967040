import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql, Link} from 'gatsby';
//import { openModal, closeModal } from '@redq/reuse-modal';
import NavbarWrapper from 'common/src/components/Navbar';
import Logo from 'common/src/components/UIElements/Logo';
import ScrollSpyMenu from 'common/src/components/ScrollSpyMenu';
// import { Container } from './navbar.style';
import { Container, MenuArea, MobileMenu} from './navbar.style';
import { Icon } from 'react-icons-kit';
import { menu } from 'react-icons-kit/feather/menu';
import { x } from 'react-icons-kit/feather/x';
import Button from 'common/src/components/Button';
import Fade from 'react-reveal/Fade';
import Scrollspy from 'react-scrollspy';
import Box from 'common/src/components/Box';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import LogoImage from 'common/src/assets/image/agency/logo-traductorapublica.png';

import { DrawerContext } from 'common/src/contexts/DrawerContext';

const Navbar = ({ navbarStyle, logoStyle, row, menuWrapper }) => {
  // const { state, dispatch } = useContext(DrawerContext);
  const [state, setState] = useState({
    mobileMenu: false,
  });

  // Toggle drawer
  // const toggleHandler = () => {
  //   dispatch({
  //     type: 'TOGGLE',
  //   });
  // };
  const toggleHandler = type => {
    if (type === 'TOGGLE') {
      setState({
        ...state,
        mobileMenu: false,
      });
    }

    if (type === 'menu') {
      setState({
        ...state,
        mobileMenu: !state.mobileMenu,
      });
    }
  };
  const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        menuItems {
          label
          path
          offset
        }
      }
    }
  `);

  const handleRemoveMenu = () => {
    setState({
      ...state,
      mobileMenu: false,
    });
  };


  return (
    <NavbarWrapper className="navbar">
      <Container>
        {/* <Box {...row}> */}
          <Logo
            href="#"
            logoSrc={LogoImage}
            title="Traductora Publica"
            logoStyle={logoStyle}
          />
          {/* <Box {...menuWrapper}> */}
          <MenuArea>
              <ScrollSpyMenu
                className="menu"
                menuItems={Data.agencyJson.menuItems}
                offset={-84}
              />
              <Button
                className="menubar"
                icon={
                  state.mobileMenu ? (
                    <Icon className="bar" icon={x} />
                  ) : (
                    <Fade>
                      <Icon className="close" icon={menu} />
                    </Fade>
                  )
                }
                color="#0F2137"
                variant="textButton"
                onClick={() => toggleHandler('menu')}
              />
            </MenuArea>
          {/* </Box> */}
        {/* </Box> */}
      </Container>
      <MobileMenu className={`mobile-menu ${state.mobileMenu ? 'active' : ''}`}>
        <Container>
          <Scrollspy
            className="menu"
            items={Data.agencyJson.menuItems}
            offset={-84}
            currentClassName="active"
          >
            {Data.agencyJson.menuItems.map((menu, index) => (
              <li key={`menu_key${index}`}>
                <AnchorLink
                  href={menu.path}
                  offset={menu.offset}
                  onClick={handleRemoveMenu}
                >
                  {menu.label}
                </AnchorLink>
              </li>
            ))}
          </Scrollspy>
        </Container>
      </MobileMenu>
    </NavbarWrapper>
  );
};

// Navbar style props
Navbar.propTypes = {
  navbarStyle: PropTypes.object,
  logoStyle: PropTypes.object,
  row: PropTypes.object,
  menuWrapper: PropTypes.object,
};

Navbar.defaultProps = {
  navbarStyle: {
    minHeight: '70px',
    display: 'block',
  },
  row: {
    flexBox: true,
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  logoStyle: {
    maxWidth: ['120px', '130px'],
  },

  menuWrapper: {
    flexBox: true,
    alignItems: 'center',
  },
};

export default Navbar;
