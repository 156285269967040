import styled from 'styled-components';
import { themeGet } from 'styled-system';

const FeatureSectionWrapper = styled.section`
  padding: 80px 0;
  overflow: hidden;
  i::before {
    width: 100%;
    text-align: center;
    margin: 0;
  }
  @media (max-width: 990px) {
    padding: 60px 0 30px 0;
  }
  @media (max-width: 680px) {
    h2{font-size: 30px;}
  }
  
  @media (max-width: 767px) {
    padding: 40px 0 30px 0;
  }
  .feature__block {
    position: relative;
    height: 100%;
    transition: box-shadow 0.3s ease;
    .icon__wrapper {
      position: relative;
      background: linear-gradient(
        -60deg,
        rgba(141,225,214,1), 
        rgba(123,216,206,1)
      );
      .flaticon-flask {
        &:before {
          margin-left: 8px;
        }
      }
      &:before,
      &:after {
        content: '';
        width: 28px;
        height: 100%;
        position: absolute;
      }
      &:before {
        transform: rotate(45deg);
        background-color: rgba(255, 255, 255, 0.15);
      }
      &:after {
        transform: rotate(-45deg);
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
    i{background:url('./assets/image/agency/translate.svg');background-size: contain;
    background-repeat: no-repeat;background-position: center;width: 100%;}
    i::before{content: "";}
    &:hover {
      box-shadow: 0 40px 90px -30px rgba(39, 79, 117, 0.2);
    }
  }
  .row {
    > .col {
      &:nth-child(-n + 3) {
        border-top: 1px solid ${themeGet('colors.lightBorder', '#f1f4f6')};
      }
      &:nth-child(3n + 3),
      &:last-child {
        border-right: 1px solid ${themeGet('colors.lightBorder', '#f1f4f6')};
      }
      @media only screen and (max-width: 991px) {
        &:nth-child(-n + 3) {
          border-top: 0;
        }
        &:nth-child(3n + 3) {
          border-right: 0;
        }
        &:nth-child(-n + 2) {
          border-top: 1px solid ${themeGet('colors.lightBorder', '#f1f4f6')};
        }
        &:nth-child(2n + 2) {
          border-right: 1px solid ${themeGet('colors.lightBorder', '#f1f4f6')};
        }
      }
      @media only screen and (max-width: 680px) {
        width:50%;
        .icon_top>div.icon__wrapper{width: 70px;height: 70px;line-height: 70px;}
        .icon_top>div.icon__wrapper i{width:70% !important;}
        .icon_top{padding:20px 10px;}
        .icon_top h2{font-size: 18px;line-height: 24px;}

        &:nth-child(-n + 2) {
          border-top: 0;
        }
        &:nth-child(2n + 2) {
          border-right: 0;
        }
        &:nth-child(-n + 1) {
          border-top: 1px solid ${themeGet('colors.lightBorder', '#f1f4f6')};
        }
        &:nth-child(1n + 1) {
          border-right: 1px solid ${themeGet('colors.lightBorder', '#f1f4f6')};
        }
        &:last-child .icon_top>div.content__wrapper h2
        {
          line-height: 24px;
          font-size: 15px;
          text-align: left;
          margin-left: 85px;
        }
      }
      &:nth-child(2) {
        .feature__block {
          .icon__wrapper {
            background: linear-gradient(
              -60deg, 
              rgba(130,188,182,1), 
              rgba(136,197,190,1)
            );
          }
        }
        &:nth-child(2) .feature__block .icon__wrapper i{background:url('./assets/image/agency/outline.svg');background-size: contain;
        background-repeat: no-repeat;background-position: center;width: 100%;}
        &:nth-child(2) .feature__block .icon__wrapper i::before{content: "";}
      }
      &:nth-child(3) {
        .feature__block {
          .icon__wrapper {
            background: linear-gradient(
              -60deg,
              rgba(233,211,171,1), 
              rgba(235,216,180,1)
            );
          }
        }
        &:nth-child(3) .feature__block .icon__wrapper i{background:url('./assets/image/agency/eye.svg');background-size: contain;
        background-repeat: no-repeat;background-position: center;width: 100%;}
        &:nth-child(3) .feature__block .icon__wrapper i::before{content: "";}
      }
      &:nth-child(4) {
        .feature__block {
          .icon__wrapper {
            background: linear-gradient(
              -60deg,
              rgba(139,143,175,1), 
              rgba(145,150,183,1)
            );
          }
        }
        &:nth-child(4) .feature__block .icon__wrapper i{background:url('./assets/image/agency/program.svg');background-size: contain;
        background-repeat: no-repeat;background-position: center;width: 100%;}
        &:nth-child(4) .feature__block .icon__wrapper i::before{content: "";}
      }
      &:nth-child(5) {
        .feature__block {
          .icon__wrapper {
            background: linear-gradient(
              -60deg,
              rgba(118, 130, 139, 1),
              rgba(138, 149, 157, 1)
            );
          }
        }
        &:nth-child(5) .feature__block .icon__wrapper i{background:url('./assets/image/agency/homework.svg');background-size: contain;
        background-repeat: no-repeat;background-position: center;width: 100%;}
        &:nth-child(5) .feature__block .icon__wrapper i::before{content: "";}
      }
      
      &:nth-child(6) {
        .feature__block {
          .icon__wrapper {
            background: linear-gradient(
              -60deg,
              rgba(234, 197, 179, 1),
              rgba(245, 206, 187, 1)
            );
          }
        }
        &:nth-child(6) .feature__block .icon__wrapper i{background:url('./assets/image/agency/subject.svg');background-size: contain;
        background-repeat: no-repeat;background-position: center;width: 100%;}
        &:nth-child(6) .feature__block .icon__wrapper i::before{content: "";}
      }
      &:last-child {
        .feature__block {
          .icon__wrapper {
            background: linear-gradient(
              -60deg,
              rgba(228, 220, 178, 1),
              rgba(241, 233, 194, 1)
            );
          }
        }
      }
      &:last-child .feature__block .icon__wrapper i{background:url('./assets/image/agency/paper.svg');background-size: contain;
      background-repeat: no-repeat;background-position: center;}
      &:last-child .feature__block .icon__wrapper i::before{content: "";}
    }
  }
  .row>div:last-child {
    width: 100%;
  }
  .row>div:last-child .icon__wrapper {
    display: inline-block;
    float: left;
    text-align: center;
    line-height: 84px;
  }
  .row>div:last-child .content__wrapper h2 {
    line-height: 84px;
  }
  
`;

export default FeatureSectionWrapper;
