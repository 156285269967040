import styled from 'styled-components';
import { themeGet } from 'styled-system';
//import WorkBG from 'common/src/assets/image/agency/sello-mujer.png';

const WorkHistoryWrapper = styled.section`
  padding: 70px 0 80px;
  overflow: hidden;
  @media (max-width: 990px) {
    padding: 50px 0 60px 0;
  }
  @media only screen and (max-width: 680px) {
    .col p{font-size:15px;}
    .col .card:last-child{background-size: contain;}
    .card{padding: 30px 15px;}
  }
  .feature__block {
    padding-right: 132px;
    @media only screen and (max-width: 1200px) {
      padding-right: 32px;
    }
    @media only screen and (max-width: 991px) {
      padding-right: 0;
      margin-bottom: 0;
    }
    @media only screen and (max-width: 767px) {
      padding-right: 0;
      margin-bottom: 40px;
    }
    .reusecore__button {
      transition: all 0.3s ease;
      &:hover {
        box-shadow: 0px 9px 20px -5px rgba(16, 172, 132, 0.57);
      }
    }
    
  }
  .content__wrapper h2{text-transform: uppercase;}
`;

const CounterUpArea = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 20px;

  @media only screen and (max-width: 1200px) {
    padding-left: 0;
  }
  @media only screen and (max-width: 991px) {
    padding-right: 0;
    margin-left: -25px;
  }
  @media only screen and (max-width: 480px) {
    margin-left: 0;
  }
  .card {
    width: calc(50% - 25px);
    margin-left: 25px;
    margin-bottom: 27px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition: box-shadow 0.3s ease-in-out;
    @media (max-width: 767px) {
      width: calc(50% - 13px);
      &:nth-child(2n + 1) {
        margin-left: 0;
      }
    }

    &:hover {
      box-shadow: 0px 16px 35px 0px rgba(16, 66, 97, 0.1);
      background-color:#EFE5BA;
    }
    &:hover h3{
      color:#fff;
    }
    &:hover p{
      color:#fff;
    }

    h3 {
      font-size: 60px;
      font-weight: 600;
      margin: 0 0 20px;
      font-family: "Poppins", sans-serif;
      color: #5F5F5F;
      @media (max-width: 990px) {
        font-size: 40px;
      }
      @media (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    p {
      color: #5F5F5F;
      font-size: 18px;
      text-align:center;
      font-weight: 400;
      margin-bottom: 7px;
      @media (max-width: 990px) {
        font-size: 14px;
        text-align: center;
      }
    }

    a {
      color: ${themeGet('colors.linkColor', '#2b9eff')};
      font-weight: 500;
      font-size: 15px;
      text-decoration: underline;
      margin-top: 7px;
      @media (max-width: 1190px) {
        font-size: 14px;
        text-align: center;
      }
    }

    &:nth-child(even) {
      position: relative;
      top: 22px;
    }

    &:last-child {
      position: relative;
      top: 22px;
      background: #FFFFFF url('./assets/image/agency/sello-mujer.webp') center center no-repeat padding-box;
      opacity: 1;
    }
  }
`;

export { CounterUpArea };
export default WorkHistoryWrapper;
