import React from 'react';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
//import FeatureImage from 'common/src/assets/image/agency/maps.png';
import Image from 'common/src/components/Image';

import Container from 'common/src/components/UI/Container';
import NewsletterSectionWrapper, {
  NewsletterForm,
} from './newsletterSection.style';

const NewsletterSection = ({ sectionHeader, sectionTitle, btnStyle }) => {
  return (
    <NewsletterSectionWrapper id="newsletterSection">
      <Container>
            <a href="https://www.google.com/maps/place/Av.+Olaz%C3%A1bal+1961,+C1428DHB+CABA/@-34.5573464,-58.4545736,17z/data=!4m5!3m4!1s0x95bcb42d277a79c5:0x1a9daf7f7424a0e!8m2!3d-34.5577526!4d-58.4543926" target="_blank">
              <picture>
                <source srcset="./assets/image/agency/maps.png" media="(min-width: 800px)"/>
                <source srcset="./assets/image/agency/maps-mobile.png" media="(max-width: 799px)"/>
                <img src="./assets/image/agency/maps-mobile.png" alt="example"/>
              </picture>
             </a>
      </Container>
    </NewsletterSectionWrapper>
  );
};

// NewsletterSection style props
NewsletterSection.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
};

// NewsletterSection default style
NewsletterSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: '56px',
  },
  // section title default style
  sectionTitle: {
    textAlign: 'center',
    fontSize: ['20px', '24px'],
    fontWeight: '400',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '0',
  },
  // button default style
  btnStyle: {
    minWidth: '152px',
    minHeight: '45px',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export default NewsletterSection;
