import styled from 'styled-components';
import { themeGet } from 'styled-system';
//import BannerBG from 'common/src/assets/image/agency/agency-banner.png';

const BannerWrapper = styled.section`
  padding-top: 150px;
  padding-bottom: 120px;
  background-image: url('./assets/image/agency/agency-banner.png');
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  overflow: hidden;
  display: flex;
  
  @media only screen and (min-width: 1367px) {
    min-height: 100vh;
  }
  @media (max-width: 990px) {
    padding-top: 100px;
    padding-bottom: 200px;
  }
  @media only screen and (max-width: 680px) {
    padding-top: 100px;
    padding-bottom: 250px;
    background-position: right bottom;
    background-size: 80%;
    .col{width: 100%;}
    .col a.btn-text {
      display: block;
      margin-top: 39px;
    }
    p{font-size: 18px;line-height: 24px;}
  }
  .row {
    position: relative;
    z-index: 1;
  }
  .button__wrapper {
    margin-top: 40px;
    .reusecore__button {
      &:first-child {
        transition: all 0.3s ease;
        &:hover {
          box-shadow: 0px 9px 20px -5px rgba(16, 172, 132, 0.57);
        }
      }
    }
  }
  .container {
    max-width: 1170px;
    width: 100%;
    justify-content: left;
    display: flex;
  }
  a.buttonstyle__ButtonStyle-kympzq.jHRotG.reusecore__button {
    background-color: #63E6D9;
    padding: 14px 20px;
    color: #fff;
    min-height: 48px;
    font-weight: 700;
    margin-right: 10px;
  }
  a.btn-text {
    color: #202B29;
    text-transform: uppercase;
    font-weight: 500;
  }
`;

const DiscountLabel = styled.div`
  display: inline-block;
  border-radius: 4em;
  padding: 7px 25px;
  box-shadow: 0px 4px 50px 0px rgba(22, 53, 76, 0.08);
  margin-bottom: 30px;
  background-color: ${themeGet('colors.white', '#ffffff')};
  @media (max-width: 767px) {
    padding: 7px 10px;
  }
`;

export { DiscountLabel };

export default BannerWrapper;
