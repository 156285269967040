import React from 'react';
import PropTypes from 'prop-types';
import CountUp from 'react-countup';
import Box from 'common/src/components/Box';
import Card from 'common/src/components/Card';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Button from 'common/src/components/Button';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import WorkHistoryWrapper, { CounterUpArea } from './workHistory.style';

const WorkHistory = ({ row, col, cardStyle, title, description,btnStyle }) => {
  return (
    <WorkHistoryWrapper id="quienesSomosSection">
      <Container>
        <Box className="row" {...row}>
          <Box className="col" {...col}>
            <FeatureBlock
              title={
                <Heading
                  content="Quienes Somos"
                  {...title}
                />
              }
              description={
                <Text
                  content="Daniela Costa, traductora pública, intérprete y abogada egresada de la Universidad de Buenos Aires y matriculada en el Colegio de Traductores Públicos de la Ciudad de Buenos Aires, lidera el equipo de profesionales de la traducción. Desde hace más de 20 años, trabajamos con pequeñas, medianas y grandes empresas nacionales y extranjeras, organismos gubernamentales y clientes particulares."
                  {...description}
                />
              }
              
            />
          </Box>
          <Box className="col" {...col}>
            <CounterUpArea>
              <Card className="card" {...cardStyle}>
                <h3>
                  +20
                </h3>
                <Text content="Años de experiencia" />
              </Card>
              <Card className="card" {...cardStyle}>
                <h3>
                  +13
                </h3>
                <Text content="Millones de palabras traducidas" />
              </Card>
              <Card className="card" {...cardStyle}>
                <h3>
                  +5
                </h3>
                <Text content="Mil documentos legalizados" />
              </Card>
              <Card className="card" {...cardStyle}>
              </Card>
            </CounterUpArea>
          </Box>
        </Box>
      </Container>
    </WorkHistoryWrapper>
  );
};

// WorkHistory style props
WorkHistory.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  sectionSubTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  cardStyle: PropTypes.object,
};

// WorkHistory default style
WorkHistory.defaultProps = {
  // WorkHistory section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // WorkHistory section col default style
  col: {
    pr: '15px',
    pl: '15px',
    width: [1, 1, 1 / 2, 1 / 2],
    flexBox: true,
    alignSelf: 'center',
  },
  // Card default style
  cardStyle: {
    p: ['20px 20px', '30px 20px', '30px 20px', '53px 40px'],
    borderRadius: '10px',
    boxShadow: '0px 8px 20px 0px rgba(16, 66, 97, 0.07)',
  },
  // WorkHistory section title default style
  title: {
    fontSize: ['26px', '26px', '30px', '40px'],
    lineHeight: '1.2',
    fontWeight: '600',
    color: '#1F1F1F',
    letterSpacing: '-0.025em',
    fontFamily: '"Poppins", sans-serif',
    mb: '20px',
  },
  // WorkHistory section description default style
  description: {
    fontSize: '16px',
    color: '#7C7C7C',
    lineHeight: '1.5',
    mb: '33px',
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
};

export default WorkHistory;
