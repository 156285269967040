import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Card from 'common/src/components/Card';
import Image from 'common/src/components/Image';
import FeatureBlock from 'common/src/components/FeatureBlock';
import Container from 'common/src/components/UI/Container';
import QualitySectionWrapper , { CounterUpArea } from './qualitySection.style';
import FeatureImage from 'common/src/assets/image/agency/surface-studio.png';

const QualitySection = ({
  row,
  col,
  cardStyle,
  title,
  featureCol,
  description,
  textArea,
  imageArea,
  imageAreaRow,
  imageWrapper,
  featureTitle,
  featureDescription,
  iconStyle,
}) => {
  const Data = useStaticQuery(graphql`
    query {
      agencyJson {
        qualityFeature {
          description
          icon
          id
          title
        }
      }
    }
  `);

  return (
    <QualitySectionWrapper id="contactoSection">
      <div class="style__ContainerWrapper-kRTGxl kuNHYN container">
        <div class="col-contact">
          <h2 class="ficAHv">CONTACTO</h2>
          <p class="contacto bajada">Para solicitar presupuesto, enviar los archivos en formato digital, escaneados o foto por email o WhatsApp, y se le responderá a la brevedad.</p>
          <p class="resaltado">Para atención, entrega o retiro de documentación, coordinar día y horario por <strong>teléfono, WhatsApp o email</strong>.</p>
        </div>

        <div class="col-contact2">
          <a class="iconmail" href="mailto:info@traductorapublica.com"><i class="demo-icon icon-mail-alt"></i>info@traductorapublica.com</a>
          <a class="icontelefono" href="tel:541147821406"><i class="demo-icon icon-phone"></i>+54 11 4782-1406</a>
          <a class="icondireccion" href="https://www.google.com/maps/place/Av.+Olaz%C3%A1bal+1961,+C1428DHB+CABA/@-34.5573464,-58.4545736,17z/data=!4m5!3m4!1s0x95bcb42d277a79c5:0x1a9daf7f7424a0e!8m2!3d-34.5577526!4d-58.4543926" target="_blank" ><i class="demo-icon icon-location"></i><span>Olazabal 1961<strong>Piso 2 dpto E, CABA, Argentina</strong></span></a> 
          <a class="iconcelular" href="https://api.whatsapp.com/send?phone=5491131474755&text=Hola.%20Quiero%20hacer%20una%20consulta." target="_blank"><i class="demo-icon icon-whatsapp"></i>15 3147-4755</a>
        </div>
      </div>
    </QualitySectionWrapper>
  );
  // return (
  //   <QualitySectionWrapper id="qualitySection">
  //     <Container>
  //         <CounterUpArea>
  //           <Box className="boxFeature">

  //             <FeatureBlock
  //               title={
  //                 <Heading
  //                   content="Contacto"
  //                   {...title}
  //                 />
  //               }
  //               description={
  //                 <Text
  //                   content="Para solicitar presupuesto, enviar los archivos en formato digital, escaneados o foto por email o WhatsApp, y se le responderá a la brevedad."
  //                   {...description}
  //                 />
  //               }
  //             />
  //             <Box>
  //               <Text content="Para atención, entrega o retiro de documentación, coordinar día y horario por teléfono, WhatsApp o email."/>
  //             </Box>
  //           </Box>

  //             <Box className="item">
  //               <Text content="info@traductorapublica.com" />
  //             </Box>
  //             <Box className="item">
  //               <Text content="Olazabal 1961 Piso 2 dpto E, CABA, Argentina" />
  //             </Box>
  //             <Box className="item">
  //               <Text content="4782-1406" />
  //             </Box>
  //             <Box className="item">
  //               <Text content="15 3147-4755"/>
  //             </Box>

  //         </CounterUpArea>
  //     </Container>
  //   </QualitySectionWrapper>
  // );
};

QualitySection.propTypes = {
  row: PropTypes.object,
  col: PropTypes.object,
  featureCol: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  button: PropTypes.object,
};

QualitySection.defaultProps = {
  // Quality section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
  },
  // Quality section iamge row default style
  imageAreaRow: {
    flexDirection: 'row-reverse',
  },
    // Card default style
    cardStyle: {
      p: ['20px 20px', '30px 20px', '30px 20px', '53px 40px'],
      borderRadius: '10px',
      boxShadow: '0px 8px 20px 0px rgba(16, 66, 97, 0.07)',
    },
  // Quality feature col default style
  featureCol: {
    width: [1, 1, 1 / 2],
    pr: '15px',
    pl: '15px',
  },
  // Quality section text area default style
  textArea: {
    width: [1, '100%', '100%', '70%', '64%'],
  },
  // Quality section title default style
  title: {
    fontFamily: '"Poppins", sans-serif',
    fontSize: ['26px', '26px', '32px', '40px'],
    lineHeight: '1.5',
    fontWeight: '300',
    color: '#0f2137',
    letterSpacing: '-0.025em',
    mb: '20px',
  },
  // Quality section description default style
  description: {
    fontSize: '16px',
    color: '#343d48cc',
    lineHeight: '2.1',
    mb: '33px',
  },
  // feature icon default style
  iconStyle: {
    width: '54px',
    height: '54px',
    borderRadius: '50%',
    bg: '#93d26e',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '24px',
    color: '#ffffff',
    overflow: 'hidden',
    mt: '6px',
    mr: '22px',
    flexShrink: 0,
  },
  // feature title default style
  featureTitle: {
    fontSize: ['18px', '20px'],
    fontWeight: '400',
    color: '#0f2137',
    lineHeight: '1.5',
    mb: '8px',
    letterSpacing: '-0.020em',
  },
  // feature description default style
  featureDescription: {
    fontSize: '15px',
    lineHeight: '1.84',
    color: '#343d48cc',
  },
};

export default QualitySection;
