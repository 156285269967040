import styled from 'styled-components';
import {
  display,
  alignItems,
  justifyContent,
  flexWrap,
  flexDirection,
  boxShadow,
  color,
  space,
  borderRadius,
  width,
  height,
} from 'styled-system';

const NavbarStyle = styled.nav`
  /* Navbar default style goes here */
  display: flex;
  align-items: center;
  min-height: 56px;
  padding: 10px 16px;

  ul li {
    margin: 0 8px;
    display:inline-block;
  }
  ul li a {
    height: 26px;
    text-align: left;
    font-size: 18px;
    letter-spacing: 0px;
    color: #202B29 !important;
    opacity: 1;
    font-family: Roboto;
  }
  
  /* Style system supported prop */
  ${display}
  ${alignItems}
  ${justifyContent}
  ${flexDirection}
  ${flexWrap}
  ${width}
  ${height}
  ${color}
  ${space}
  ${boxShadow}
  ${borderRadius}
`;

NavbarStyle.displayName = 'NavbarStyle';

export default NavbarStyle;
